@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600&family=Poiret+One&family=Poppins:wght@100;200;400;700;800&family=Roboto+Mono:wght@100;200;300;500;700&family=Source+Sans+3:wght@300;400;600;700&family=Ubuntu:wght@300;500;700&display=swap');

body,
html {
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.App {
  width: 100vw;
  display: grid;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #37a6de4d;
}

::-webkit-scrollbar-thumb {
  background: #0060a4;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #173a63;
}

.menu-link:hover {
  background-color: black;
}